<template>
  <div>
    <Header/>
    <section class="signin">
      <div class="container">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col-12 col-md-7 d-1">
            <img class="img-fluid" alt="home image" src="../../assets/image/icon-images/home.png">
          </div>
          <!-- Col -->
          <div class="col-12 col-md-5 d-flex justify-content-end d-1">
           <form class="signin-container">
             <h4 class="signin-title">შესვლა</h4>
             <input type="email" class="auth-control d-block" placeholder="ელ.ფოსტა">
             <input type="password" class="auth-control" placeholder="პაროლი">
             <div class="d-flex flex-column">
               <div class="text-center">
                 <span>არ გაქვთ ანგარიში?</span>
                 <a href="#">რეგისტრაცია</a>
               </div>
               <button class="btn-auth">შესვლა</button>
             </div>
           </form>
          </div>
          <!-- Col -->
          <div class="col-12 d-1">
           <form class="signin-container">
             <h4 class="signin-title">შესვლა</h4>
             <input type="email" class="auth-control d-block" placeholder="ელ.ფოსტა">
             <input type="password" class="auth-control" placeholder="პაროლი">
             <div class="d-flex flex-column">
               <div class="text-center">
                 <span>არ გაქვთ ანგარიში?</span>
                 <a href="#">რეგისტრაცია</a>
               </div>
               <button class="btn-auth">შესვლა</button>
             </div>
           </form>
          </div>
        </div>
        <!-- End Row -->
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: "Signin",
  components: {
    Header,
    Footer
  },
  methods: {
    scrollY() {
      window.scrollTo(0, 0)
    }
  },
  mounted() {
    this.scrollY();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}
</style>