<template>
  <div>
    <Header/>
    <section class="page404">
      <!-- Container -->
      <div class="container">
        <!-- Row -->
        <div class="row justify-content-center align-items-center">
          <!-- Col -->
          <div class="col-12 d-flex flex-column justify-content-center align-items-center">
            <img src="../assets/image/404.png" class="img-fluid" alt="404 not found">
            <h1>გვერდი არ მოიძებნა</h1>
            <router-link to="/Home" class="btn-motivation">მთავარი გვერდი</router-link>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- End Container -->
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "404",
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}
.page404 {
  margin-top: 109px;
}
.page404 h1 {
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 34px;

  font-feature-settings: 'case' on;

  color: var(--Second-Color);

  margin-top:16px;
  margin-bottom: 44px;
}
.page404 .btn-motivation {
  max-width: 368px;
}

@media screen and (max-width: 767px) {
  .footer {
    margin-top: 205px;
  }
  .page404 {
    margin-top: 89px;
  }
  .page404 h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .page404 .btn-motivation {
    font-size: 16px;
    font-weight: 400;
    max-width: 368px;
  }
}
</style>