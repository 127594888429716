<template>
  <div>
    <header id="header" class="header">
      <div class="container">
        <div class="row align-items-center">
          <nav class="col-12 d-flex align-items-center">
            <a class="logo" href="/Home">
              <img src="../assets/image/logo.png" alt="logo">
            </a>
            <div class="menu ms-auto" :class="isActive == true ? 'active' : ''">
              <ul class="navbar-menu">
                <li class="nav-link"><router-link @click="navLinkForBody" to="/FAQ">ვაკანსიის განთავსება</router-link></li>
                <li class="nav-link d-none"></li>
                <li class="nav-link"><router-link @click="navLinkForBody" to="/Training">სასწავლო პროგრამები</router-link></li>
                <li class="nav-link btn-link"><a href="https://app.motivation.ge/Signin">შესვლა</a></li>
              </ul>
              <ul class="navbar-menu-auth">
                <li class="nav-link-auth">გაქვთ ანგარიში? <a href="https://app.motivation.ge/Signin"><span>შესვლა</span></a> </li>
                <li class="nav-link-auth"><a href="https://app.motivation.ge/Signup" to="#"><button class="btn-auth">რეგისტრაცია</button></a></li>
              </ul>
            </div>
            <div class="burger-container" :class="{ active: isActive }" @click="menu">
              <div class="burger_main-line"></div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    menu() {
      this.isActive = !this.isActive;
      const body = document.body;
      body.classList.toggle('overflow-hidden');
    },
    navLinkForBody() {
      const body = document.body;
      body.classList.remove('overflow-hidden');
    }
  }
}
</script>

<style scoped>

</style>