<template>
  <div>
    <Header/>
    <div class="training">
      <!-- Container -->
      <div class="container">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col-12 mb-4">
            <h1>სასწავლო პროგრამები</h1>
          </div>
          <!-- Col -->
          <div class="col-12 col-md-4 mt-3 mt-md-0" v-for="item in ResponseData" :key="item.id">
            <!-- Card -->
            <div class="card card-training">
              <div class="card-body">
                <div class="d-flex justify-content-center" style="height: 200px">
                  <img v-if="item.file != ''" class="img-fluid" alt="training image" :src="item.file">
                  <img v-else class="img-fluid" alt="training image" src="../../assets/image/noavatar.jpeg">
                </div>
                <h5 class="card-title">{{ item.name }}</h5>
                <p class="card-text" v-html="item.short_desc"></p>
                <router-link v-if="item.status == 'published'" :to="'/Registration/'+item.id"><button class="btn-motivation"> დეტალურად</button></router-link>
                <button v-else class="btn-motivation" disabled> მალე ...</button>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- Col -->
          <div class="col-12 mt-3 d-flex justify-content-center" v-if="ResponseData == ''">
            <img src="../../assets/image/training/noResult.svg" class="img-fluid w-50">
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- End Container -->
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "Training",
  data() {
    return {
      ResponseData: {},
      isLoading: true
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    getResults() {

      this.isLoading = true;
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/traning/LoadData')
          .then(response => {
            window.scroll(0, 0);
            this.ResponseData = response.data;
            this.isLoading = false;
          });
    }
  },
  mounted() {
    this.getResults();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}

h1,h2,h3,h4,h5,h6 {
  font-family: var(--First-Font);
  font-weight: 500;
  color: var(--Second-Color);
}

.training {
  margin-top: 109px;
}
.training h1 {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: var(--Second-Color);
}
.training h5 {
  font-size: 18px;
}
.training .card-training {
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  padding: 33px 17px;
  transition: all 0.3s;
}
.training .card-training:hover {
  border: 1px solid var(--Second-Color-Box-Hover);
  transform: translateY(-2px);
}
.training .card-training .card-body {
  padding: 0;
}
.training .card-training img {
  margin-bottom: 40px;
}
.training .card-training .card-title {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--Second-Color);
  margin-bottom: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.training .card-training .card-text {
  font-family: var(--First-Font);
  font-family: 'FiraGO';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(66, 66, 66, 0.5);
  margin-bottom: 32px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 100px;
}
.training .card-training .btn-motivation {
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;

  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--Second-Color);
}
.training .card-training .btn-motivation:disabled {
  background: #ffea69;
}
@media screen and (max-width: 767px) {
  .training {
    margin-top: 89px;
  }
  .training .card-training .card-title {
    font-size: 14px;
  }
  .training .card-training .card-text {
    font-size: 14px;
  }
  .footer {
    margin-top: 89px;
  }
}
</style>