<template>
  <div>
    <loading :active="isLoading"
             :can-cancel="true"

             :z-index="10000"
             :color="'var(--First-Color)'"></loading>
    <Header/>
    <section class="company"  v-if="!isLoading">
      <div class="container">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="card-left-detail">
                  <img  v-if="companyDetail.company_logo" :src="companyDetail.company_logo" class="card-image" alt="Company logo">
                  <img v-else class="company-logo" src="../../../assets/image/noavatar.jpeg" alt="Company logo">
                  <div class="d-block">
                    <h6>{{ companyDetail.brandname}}</h6>
                    <span>ვაკანსია: ({{ companyDetail.cnt }})</span>
                  </div>
                </div>
                <div class="card-right-detail">
                  <a v-if="companyDetail.facebook" :href="companyDetail.facebook" target="_blank">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="16" fill="#EDEDED"/>
                      <path d="M14 13.3333H12V16H14V24H17.3333V16H19.7333L20 13.3333H17.3333V12.2C17.3333 11.6 17.4667 11.3333 18.0667 11.3333H20V8H17.4667C15.0667 8 14 9.06667 14 11.0667V13.3333Z" fill="#424242"/>
                    </svg>

                  </a>
                  <a v-if="companyDetail.website" :href="companyDetail.website" target="_blank">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="16" fill="#EDEDED"/>
                      <circle cx="16" cy="16" r="7.5" stroke="#424242"/>
                      <path d="M18.5 16C18.5 18.1651 18.1701 20.1029 17.6532 21.4813C17.394 22.1723 17.0975 22.6969 16.7936 23.0396C16.4892 23.383 16.2199 23.5 16 23.5C15.7801 23.5 15.5108 23.383 15.2064 23.0396C14.9025 22.6969 14.606 22.1723 14.3468 21.4813C13.8299 20.1029 13.5 18.1651 13.5 16C13.5 13.8349 13.8299 11.8971 14.3468 10.5187C14.606 9.82765 14.9025 9.30314 15.2064 8.96038C15.5108 8.61704 15.7801 8.5 16 8.5C16.2199 8.5 16.4892 8.61704 16.7936 8.96038C17.0975 9.30314 17.394 9.82765 17.6532 10.5187C18.1701 11.8971 18.5 13.8349 18.5 16Z" stroke="#424242"/>
                      <path d="M8.5 16H23.5" stroke="#424242" stroke-linecap="round"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Col -->
          <div class="col-12">
            <div class="info-box">
              <div class="info" v-for="item in VacancyData" :key="item.id" >

                <div class="section-one" :to="'/VacancyInner/'+item.id">
                  <router-link :to="'/VacancyInner/'+item.id">
                    <img   v-if="item.CompanyDetail.logo" class="company-logo"  :src="item.CompanyDetail.logo" alt="Company logo">
                    <img v-else class="company-logo" src="../../../assets/image/noavatar.jpeg" alt="Company logo">
                  </router-link>
                  <div class="inside-box">
                    <router-link :to="'/VacancyInner/'+item.id"><h6>{{ item.PositionDetail.text }}</h6></router-link>
                    <router-link :to="'/VacancyInner/'+item.id"><p>{{ item.IndustryDetail.text }}</p></router-link>
                    <router-link :to="'/VacancyInner/'+item.id"><p class="company-link">{{ item.CompanyDetail.brandname }}</p></router-link>
                  </div>
                </div>
                <router-link :to="'/VacancyInner/'+item.id" class="section-two">
                  <div class="col-5">
                    <div class="inside-box-two">
                      <img src="../../../assets/image/icons/location-icon.svg" alt="">
                      <p>{{ item.VacancyLocation.name }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-two">
                      <img src="../../../assets/image/icons/experience-icon.svg" alt="">
                      <p v-if="item.work_experience=='1'">გამოცდილების გარეშე</p>
                      <p v-if="item.work_experience=='2'">დამწყები</p>
                      <p v-if="item.work_experience=='3'">საშუალო დონე</p>
                      <p v-if="item.work_experience=='4'">პროფესიონალი</p>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="inside-box-special">
                      <img src="../../../assets/image/icons/lari-icon.svg" alt="">
                      <p v-if="item.amount_type=='1'">{{ Number(item.avarage_min_bonus).toLocaleString() }}{{item.CurrencyDetail.text}} -  {{ Number(item.avarage_max_bonus).toLocaleString()  }}{{item.CurrencyDetail.text}}</p>
                      <p v-if="item.amount_type=='2'">{{ Number(item.min_amount).toLocaleString() }}{{ item.CurrencyDetail.text }} - {{ Number(item.max_amount).toLocaleString() }}{{ item.CurrencyDetail.text }}</p>
                      <p v-if="item.amount_type=='3'">{{ Number(item.fixed_amount).toLocaleString() }}{{ item.CurrencyDetail.text }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-special">
                      <img src="../../../assets/image/icons/time-progress-icon.svg" alt="">
                      <p>{{ item.work_schedule.name}}</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- End Row -->
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Loading from 'vue3-loading-overlay';
export default {
  name: "Company",
  data() {
    return {
      isLoading: true,
      companyDetail:[],
      VacancyData:[]
    }
  },
  components: {
    Header,
    Footer,
    Loading
  },
  methods: {
    LoadData(){
      this.isLoading = true;
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/get_company_by_id/' + this.$route.params.id)
            .then(response => {
              this.companyDetail = response.data;
              this.isLoading = false;

            }).catch(error => {
          this.error = error.response.data;
          this.isLoading = false;
      })
    },
    LoadVacancyData(){
      this.isLoading = true;
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/get_vacancy_by_detail/' + this.$route.params.id)
          .then(response => {
            this.VacancyData = response.data;
            this.isLoading = false;

          }).catch(error => {
        this.error = error.response.data;
        this.isLoading = false;
      })
    },
    scroll() {
      window.scroll(0, 0);
    }
  },
  mounted() {
    this.LoadVacancyData();
    this.LoadData();
    this.scroll();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}

.company .info-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.company .info{
  display: grid;
  gap: 8px;

  padding: 18px 18px 22px;
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  transition: all 0.3s;

}
.company .info:hover {
  border: 1px solid var(--Second-Color-Box-Hover);
  transform: translateY(-4px);
}
.company .info .inside-box{
  display: grid;
  gap: 5px;
}
.company .info .inside-box-two{
  display: flex;
  gap: 9.04px;
}
.company .info .inside-box-special{
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 9.04px;
}
.company .info .section-one{
  display: flex;
  align-items: start;
  height: 100px;
  gap: 16px;

}
.company .info .section-two{
  display: flex;
  flex-wrap: wrap;
}
.company .info .company-logo {
  width: 57px;
  height: 57px;
  object-fit: cover;
}
.company-logo {
  width: 57px;
  height: 57px;
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 50%;
  object-fit: cover;
}

.company .info h6 {
  font-family: var(--First-Font);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;

  color: #424242;
}
.company .info p{
  font-family: var(--Third-Font);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;

  color: rgba(66, 66, 66, 0.5);
}
.company .info .company-link{
  font-family: var(--Third-Font);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;

  color: #2200CC;
}

@media screen and (max-width:1399px) {
  .company .info-box{
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 18px;
  }
  .company .info {
    gap: 8px;
    padding:21px 20px;
  }
  .company .info .inside-box-special{
    margin-top: 10px;
  }
  .company .info p{
    font-size: 13px;
  }
  @media screen and (max-width:1200px) {
    .company .info-box {
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 18px;
    }
    .company .info {
      gap: 8px;
      padding:21px 18px;
    }
    .company .info .inside-box-special {
      margin-top: 10px;
    }
    .company .info p {
      font-size: 13px;
    }
  }

  @media screen and (max-width:991px) {
    .company .info-box {
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 14px;
    }
    .company .info {
      gap: 8px;
      padding:21px 18px;
    }
    .company .info .inside-box-special{
      margin-top: 10px;
    }
    .company .info p{
      font-size: 12px;
    }
  }

}
@media screen and (max-width:768px) {
  .company .info-box {
    grid-template-columns: 100%;
    width: 100%;
    gap: 16px;
  }
  .company .info {
    gap: 8px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .company .info .inside-box-special{
    margin-top: 10px;
  }
  .company .info p{
    font-size: 13px;
  }

  .footer {
    margin-top: 44px;
  }
}
@media screen and (max-width:428px) {
  .company .info-box{
    grid-template-columns: 100%;
    width: 100%;
    gap: 16px;
  }
  .company .info {
    gap: 8px;
    padding:21px 23px 21px 25px;
  }
  .company .info .inside-box-special{
    margin-top: 10px;
  }
  .company .info p{
    font-size: 13px;
  }
}
@media screen and (max-width:375px) {
  .company .info-box{
    grid-template-columns: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    gap: 16px;
  }
  .company .info {
    gap: 8px;
    padding:21px 13px;
  }
  .company .info .inside-box-special {
    margin-top: 10px;
  }
  .company .info .company-link {
    font-size: 12px;
  }
  .company .info h6 {
    font-size: 13px;
  }
  .company .info p {
    font-size: 12px;
  }
  .company .info .company-logo {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width:320px) {
  .company .info-box{
    grid-template-columns: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    gap: 16px;
  }
  .company .info {
    gap: 8px;
    padding:21px 10px;
  }
  .company .info .inside-box-special{
    margin-top: 10px;
  }
  .company .info .company-link {
    font-size: 11px;
  }
  .company .info h6 {
    font-size: 13px;
  }
  .company .info p {
    font-size: 11px;
  }
  .company .info .company-logo {
    width: 50px;
    height: 50px;
  }
}

/* Cards */
.company .col-12:nth-child(1) {
  margin-bottom: 60px;
}
.company .card  {
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  padding: 25px 62px 25px 44px;
}
.company .card .card-body  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.company .card .card-body .card-left-detail {
  display: flex;
  align-items: center;
  gap: 28px;
}
.company .card .card-body .card-left-detail .card-image {
  max-width: 87px;
  width: 100%;
  height: 87px;
  object-fit: cover;
  border-radius: 50%;
}
.company .card .card-body .card-left-detail h6 {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: var(--Second-Color);
  margin-bottom: 8px;
}
.company .card .card-body .card-left-detail span {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: rgba(66, 66, 66, 0.5)
}
.company .card .card-body .card-right-detail {
  display: flex;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  .company .col-12:nth-child(1) {
    margin-bottom: 32px;
  }
  .company .card  {
    padding: 21px 12px 21px 18px;
  }
  .company .card .card-body .card-left-detail {
    gap: 16px;
  }
  .company .card .card-body .card-left-detail .card-image {
    max-width: 48px;
    height: 48px;
  }
  .company .card .card-body .card-left-detail h6 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .company .card .card-body .card-left-detail span {
    font-size: 12px;
    line-height: 18px;
  }
  .company .card .card-body .card-right-detail {
    gap: 8px;
  }
  .company .card .card-body .card-right-detail svg {
    width: 24px;
    height: 24px;
  }
}
</style>