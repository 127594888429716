import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vue Select Inputs Forms
import 'vue-select/dist/vue-select.css';
// Bootstrap
import '@/assets/css/bootstrap.min.css';
// Custom Css
import '@/assets/css/style.css'


// loading spinner Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'


// Axios
import VueAxios from "vue-axios";
import axios from "axios";


createApp(App).use(VueAxios, axios).use(store).use(router).mount('#app')
