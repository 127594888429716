<template>
  <loading :active="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"

           :z-index="10000"
           :color="'var(--First-Color)'"></loading>
  <div>
    <Header/>
    <section class="negotiations">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-4">
            <h1 class="font-weight-bold">მოლაპარაკებების ომი</h1>
          </div>
          <div class="col-12 mb-3">
            <iframe width="100%" height="450" :src="ResponseData.youtube" title="დავით კენჭაძის საავტორო პროგრამის, მოლაპარაკებების ომის დემო ვერსია" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="col-12 mt-4 mb-5">
            <p v-html="ResponseData.text_front"></p>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: "NegotiationsWar",
  data() {
    return {
      isLoading: true,
      ResponseData: {},
      error: ''
    }
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    onCancel() {

    },
    NegitiationsLoadData() {
      this.axios.get(
          process.env.VUE_APP_BACKEND_URL+'/public/dealstatic/loadData'
      ).then(response => {
        window.scroll(0, 0);
        this.ResponseData = response.data;
        this.isLoading = false;
      }).catch(error => {
        this.error = error.response.data.error;
        console.log(error);
      })

    },
  },
  mounted() {
    this.NegitiationsLoadData();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}

.negotiations h1 {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: var(--Second-Color);
}

@media screen and (max-width: 767px) {
  .negotiations {
    margin-top: 89px;
  }
}
</style>