<template>
  <div>
    <section class="category">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="category-box">
              <h4>კატეგორიები</h4>
            </div>
          </div>

          <div class="col-12">
            <div class="content-box">
              <router-link v-for="item in ResponseData" :key="item.id" :to="`/VacancyList`+'?type='+item.type+'&id='+item.id" class="box">
                <img :src="item.file" alt="vacancy category">
                <div class="detail">
                  <h6 class="text-center">{{ item.name }}</h6>
                  <p>({{ item.cnt }} ვაკანსია)</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default{
  name: 'Category',
  data() {
    return {
      ResponseData: [{
        name: '',
        file:'',
        cnt: ''
      }]
    }
  },
  methods: {
    categoryData() {
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/categories')
          .then(response => {
            this.ResponseData = response.data;
            this.isLoading = false;
          });
    }
  },
  mounted() {
    this.categoryData();
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'FiraGO-Medium.ttf';
  src: url(../assets/fonts/FiraGO-Medium.ttf);
}
.category-box{
  margin-bottom: 36px;

}
.content-box{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.box{
  display: grid;
  justify-items: center;
  width: 100%;
  height: 190px;
  padding:26px 3px 25px;
  background: #FFFFFF;
  color: rgba(66, 66, 66, 0.5);
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.3s;
}
.box:hover {
  border: 1px solid var(--Second-Color-Box-Hover);
  transform: translateY(-4px);
}

.box .detail {
  text-align: center;
}

h4{
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #424242;
}
/*.col{*/
/*  display: grid;*/
/*  justify-items: center;*/
/*  max-width: 237px;*/
/*  width: 100%;*/
/*  height: 190px;*/
/*  padding: 26px 0 25px;*/
/*  background: #FFFFFF;*/
/*  color: rgba(66, 66, 66, 0.5);*/
/*  border: 1px solid rgba(66, 66, 66, 0.1);*/
/*  border-radius: 10px;*/
/*  text-decoration: none;*/
/*}*/

img{
  width: 56px;
  height: 56px;
}

h6{
  margin-top: 20px;
  margin-bottom: 0!important;
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #424242;
}
/*.vacancy-calc-box{*/
/*  height:22px;*/
/*}*/
p{
  margin-top:0!important;
  margin-bottom: 0;
  font-family: var(--First-Font);
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
}

@media screen and (max-width:1366px) {
  .category-box{
    margin-bottom: 32px;
  }
  h4{
    font-size: 18px;
    line-height: 22px;
  }
  .content-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px
  }
  /*.col{*/
  /*  max-width: 237px;*/
  /*  width: 100%;*/
  /*  height: 160px;*/
  /*  padding-top: 23px;*/
  /*  padding-bottom: 20px;*/
  /*}*/

  img{
    width: 56px;
    height: 56px;
  }
  h6{
    font-size: 14px;
    line-height: 25px;
  }
  p{
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media screen and (max-width:1199px) {
  .category-box{
    margin-bottom: 0;
  }
  .content-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
  }
}
@media screen and (max-width:1200px) {
  .row{
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  /*.col{*/
  /*  padding-top: 20px;*/
  /*  padding-bottom: 20px;*/
  /*}*/
  h6{
    font-size: 14px;
    line-height: 25px;
  }
  img{
    width: 50px;
    height: 50px;
  }
  p{
    font-size: 13px;
    line-height: 22px;
  }
}
@media screen and (max-width:992px) {
  .content-box{
    gap: 5px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  h6{
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width:767px) {
  .content-box{
    gap: 5px;
    grid-template-columns: 1fr 1fr;
  }
  .box{
    max-width: 100%;
  }
}

@media screen and (max-width:544px) {
  .box{
    /*max-width: 250px;*/
    width: 100%;
    height: 174px;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .content-box{
    gap: 5px;
    grid-template-columns: 1fr 1fr;
  }
  .category-box{
    margin-top: 44px;
  }
  h4{
    font-size: 18px;
    line-height: 19px;
  }
  img{
    width: 50px;
    height: 50px;
  }
  h6{
    margin-top: 10px;
    margin-bottom: 0.3rem;
    font-size: 14px;
    line-height: 18px;
  }
  p{
    font-size: 12px;
    margin-top: 8px;
    line-height: 20px;

  }
}

@media screen and (max-width:472px) {

  .box{
    display: flex;
    align-items: center;
    height: 145px;
    width: 100%;
    padding: 30px;
    gap: 30px;
  }
  .box .detail {
    text-align: start;
  }
  .box .detail h6 {
    font-size: 16px;
    text-align: start!important;
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 10px!important;
  }
  .content-box{
    grid-template-columns: 1fr;
    gap: 5px;
    justify-content: center;
  }
  .category-box{
    margin-bottom: 0;
    margin-top: 44px;
  }
  h4{
    font-size: 16px;
  }
  img{
    width: 50px;
    height: 50px;
  }
  h6{
    font-size: 14px;
  }
  p{
    font-size: 14px;
    margin-top: 4px!important;
  }
}
@media screen and (max-width: 390px) {
  .content-box{
    grid-template-columns: 1fr ;
    gap: 5px;
  }
  .box .detail h6{
    font-size: 14px;
  }
  .box .detail p{
    font-size: 14px;
  }
  .box {
    max-width: 100%;
  }
}
@media screen and (max-width: 362px) {
  img{
    width: 46px;
    height: 46px;
  }
  .box .detail h6{
    font-size: 14px;
  }
  .box .detail p{
    font-size: 14px;
  }
}
</style>
