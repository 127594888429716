<template>
  <div>
    <Header/>
    <div class="training">
      <!-- Container -->
      <div class="container">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col-12 mb-4">
            <h1>სასწავლო პროგრამები</h1>
          </div>
          <!-- Col -->
          <div class="col-12 col-md-7">
            <!-- Card -->
            <div class="card card-training">
              <div class="card-body">
                <div class="d-flex align-items-start align-items-md-center justify-content-between justify-content-md-center">
                  <img v-if="ResponseData.file != ''" class="img-fluid" alt="training image" :src="ResponseData.file">
                  <img v-else class="img-fluid w-50" alt="training image" src="../../assets/image/noavatar.jpeg">
                  <div class="d-flex flex-column">
                    <h5 class="card-title">{{ResponseData.name}}</h5>
<!--                    <p v-if="ResponseData.mentors" class="card-text">ლექტორი: <br> {{ResponseData.mentors }}</p>-->
                  </div>
                </div>
              </div>
              <div class="card-body flex-column">
                <p class="inner-description" v-html="ResponseData.desc"></p>
                <div class="d-flex w-100 justify-content-between align-items-end">
                  <div class="align-self-start">
                    <h5 class="card-title">ღირებულება თვეში:</h5>
                    <div v-if="ResponseData.price " class="card-price text-center">{{ ResponseData.price }} ₾</div>
                  </div>
                  <a href="#" class="btn-motivation mt-3 d-none d-md-flex">რეგისტრაცია</a>
                </div>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- Col -->
          <div class="col-12 col-md-5">
            <Form v-if="ResponseData" v-slot="{ handleSubmit }" as="div">
              <form @submit="handleSubmit($event, registration)" class="signin-container float-md-end">
                <h4 class="signin-title">რეგისტრაცია</h4>

                <!-- For Teenager and Hibrid -->
                <div v-if="ResponseData.type_registration.id === 'hibrid' || ResponseData.type_registration.id === 'teenager'">
                  <label>მოსწავლის სახელი და გვარი</label>
                  <Field type="text" :rules="isRequired" class="auth-control" name="name" v-model="form.name" placeholder="სახელი და გვარი" />
                  <ErrorMessage class="text-danger error-msg" name="name" />
                </div>
                <div v-if="ResponseData.type_registration.id  === 'hibrid' || ResponseData.type_registration.id === 'teenager'">
                  <label>მოსწავლის ელ.ფოსტა</label>
                  <Field type="email" :rules="validateEmail" class="auth-control" name="email" v-model="form.email" placeholder="ელ.ფოსტა" />
                  <ErrorMessage class="text-danger error-msg" name="email" />
                </div>
                <div v-if="ResponseData.type_registration.id  === 'hibrid' || ResponseData.type_registration.id === 'teenager'">
                  <label>მოსწავლის ტელეფონის ნომერი</label>
                  <Field type="number" :rules="isRequired" class="auth-control" name="mobile" v-model="form.mobile" placeholder="მობილური" />
                  <ErrorMessage class="text-danger error-msg" name="mobile" />
                </div>
                <div v-if="ResponseData.type_registration.id  === 'teenager'">
                  <label>მოზარდის ასაკი</label>
                  <Field type="number" :rules="isRequired" class="auth-control" name="age" v-model="form.age" placeholder="მოზარდის ასაკი" />
                  <ErrorMessage class="text-danger error-msg" name="age" />
                </div>
                <div v-if="ResponseData.type_registration.id  === 'hibrid'">
                  <label>მოსწავლის ასაკი</label>
                  <Field v-model="form.age_type" :rules="isRequired" type="text" name="age_type">
                    <v-select class="auth-control" name="age_type" placeholder="მოსწავლის ასახკი" :reduce="(option) => option.id" label="text" :options="[
                        {text: '14-18 წლამდე', id: 'under'},
                        {text: '18 დან ზემოთ', id: 'not_under'}
                    ]" v-model="form.age_type"></v-select>
                    <ErrorMessage class="text-danger error-msg" name="age_type" />
                  </Field>
                </div>
                <div v-if="form.age_type === 'under' || ResponseData.type_registration.id  === 'teenager'">
                  <label>მშობლის სახელი გვარი</label>
                  <Field type="text" :rules="isRequired" class="auth-control" name="parent_name" v-model="form.parent_name" placeholder="მშობლის სახელი და გვარი" />
                  <ErrorMessage class="text-danger error-msg" name="parent_name" />
                </div>
                <div v-if="form.age_type === 'under' || ResponseData.type_registration.id  === 'teenager'">
                  <label>მშობლის ელ.ფოსტა</label>
                  <Field type="email" :rules="validateEmail" class="auth-control" name="parent_email" v-model="form.parent_email" placeholder="მშობლის ელ.ფოსტა" />
                  <ErrorMessage class="text-danger error-msg" name="parent_email" />
                </div>
                <div v-if="form.age_type === 'under' || ResponseData.type_registration.id  === 'teenager'">
                  <label>მშობლის ტელეფონის ნომერი</label>
                  <Field type="number" :rules="isRequired" class="auth-control" name="parent_mobile" v-model="form.parent_mobile" placeholder="მშობლის ტელეფონის ნომერი" />
                  <ErrorMessage class="text-danger error-msg" name="parent_mobile" />
                </div>
                <!-- End For Teenager and Hibrid -->

                <!-- For Company -->
                <div v-if="ResponseData.type_registration.id  === 'company'">
                  <label>კომპანიის დასახელება</label>
                  <Field type="text" :rules="isRequired" class="auth-control" name="company_name" v-model="form.company_name" placeholder="კომპანიის დასახელება" />
                  <ErrorMessage class="text-danger error-msg" name="company_name" />
                </div>
                <div v-if="ResponseData.type_registration.id  === 'company'">
                  <label>საკონტაქტო პირის სახელი და გვარი</label>
                  <Field type="text" :rules="isRequired" class="auth-control" name="company_contact_name" v-model="form.company_contact_name" placeholder="საკონტაქტო პირის სახელი და გვარი" />
                  <ErrorMessage class="text-danger error-msg" name="company_contact_name" />
                </div>
                <div v-if="ResponseData.type_registration.id  === 'company'">
                  <label>საკონტაქტო პირის ელ.ფოსტა</label>
                  <Field type="email" :rules="validateEmail" class="auth-control" name="company_email" v-model="form.company_email" placeholder="საკონტაქტო პირის ელ.ფოსტა" />
                  <ErrorMessage class="text-danger error-msg" name="company_email" />
                </div>
                <div v-if="ResponseData.type_registration.id  === 'company'">
                  <label>საკონტაქტო პირის ტელეფონის ნომერი</label>
                  <Field type="number" :rules="isRequired" class="auth-control" name="company_mobile" v-model="form.company_mobile" placeholder="საკონტაქტო პირის ტელეფონის ნომერი" />
                  <ErrorMessage class="text-danger error-msg" name="company_mobile" />
                </div>
                <!-- End For Company -->

                <!-- Everyone -->
                <div>
                  <label>რატომ გსურს მოცემული კურსის გავლა?</label>
                  <Field v-model="form.comment" :rules="isRequired" type="text" name="comment">
                    <textarea class="auth-custom-control" rows="5" name="comment" v-model="form.comment"></textarea>
                    <ErrorMessage class="text-danger error-msg" name="comment" />
                  </Field>
                </div>
                <!-- End Everyone -->

                <div class="d-flex flex-column">
                  <button class="btn-auth" :disabled="checkForm" data-bs-toggle="modal" data-bs-target="#registrationSuccess">რეგისტრაცია</button>
                </div>
              </form>
            </Form>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- End Container -->
    </div>
    <Footer/>


    <!-- Registration Successs -->
    <div class="modal fade" id="registrationSuccess" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">სტატუსი</h5>
            <button v-if="checkRegistration" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body rounded-bottom p-0 position-relative">
            <div v-if="!checkRegistration" class="alert alert-light py-5" role="alert">
              <loading :active="!checkRegistration"
                       :can-cancel="true"
                       :on-cancel="onCancel"
                       class="position-relative"
                       :z-index="10000"
                       :color="'var(--First-Color)'"></loading>
            </div>
            <div v-if="checkRegistration" class="alert alert-success d-flex flex-column justify-content-center align-items-center text-center gap-3 rounded-0 rounded-bottom 0 m-0 border-0 mh-100 pb-5" role="alert">
              <img class="check_Status" src="../../assets/image/training/checksuccess.png">
              თქვენ წარმატებით გაიარეთ რეგისტრაცია
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { Form, Field, ErrorMessage } from 'vee-validate';
import vSelect from "vue-select";
import Loading from 'vue3-loading-overlay';

export default {
  name: "Registration",
  data() {
    return {
      ResponseData: {
        type_registration:{}
      },
      isLoading: true,
      form: {
        name: '',
        mobile: '',
        email: '',
        parent_name: '',
        parent_mobile: '',
        parent_email: '',
        age_type: '',
        age: '',

        company_name: '',
        company_contact_name: '',
        company_email: '',
        company_mobile: '',

        comment: ''
      },
      checkRegistration: false,
      checkForm: true
    }
  },
  components: {
    Header,
    Footer,
    Form,
    Field,
    ErrorMessage,
    vSelect,
    Loading
  },
  methods: {
    categoryData() {
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/traning/get_by_id/'+this.$route.params.id)
          .then(response => {
            window.scroll(0,0);
            this.ResponseData = response.data;
            this.isLoading = false;
          });
    },
    registration() {
      this.axios.post(process.env.VUE_APP_BACKEND_URL+'/public/traning/signup/'+ this.$route.params.id , this.form)
          .then(res => {
            console.log(res)
                this.checkRegistration = true;
                this.checkForm = true;
          }
          ).catch(error => {
            console.log(error)
          })
    },
    onCancel() {

    },
    validateEmail(value) {
      // if the field is empty
      if (!value) {
        this.checkForm = true;
        return 'აუცილებელი ველი';
      }
      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        this.checkForm = true;
        return 'გთხოვთ მიუთითოთ სწორი ელ.ფოსტა';
      }
      // All is good

      return true;
    },
    isRequired(value) {
      if (value && value.trim()) {

        if(this.form.name != '' && this.form.mobile != '' && this.form.email != '' && this.form.comment != '' && this.form.age_type === "not_under") {
          this.checkForm = false;
        } else if(this.form.name != '' && this.form.mobile != '' && this.form.email != '' && this.form.comment != '' && this.form.age_type === "under" && this.form.parent_name != '' && this.form.parent_mobile != '' && this.form.parent_email != '') {
          this.checkForm = false;
        } else if (this.form.name != '' && this.form.mobile != '' && this.form.email != '' && this.form.comment != '' && this.form.age != '' && this.form.parent_name != '' && this.form.parent_mobile != '' && this.form.parent_email != '') {
          this.checkForm = false;
        } else if (this.form.company_name != '' && this.form.company_email != '' && this.form.company_contact_name != '' && this.form.company_mobile != '' && this.form.comment != '') {
          this.checkForm = false;
        }
        return true;
      }

      this.checkForm = true;
      return 'აუცილებელი ველი';
    },
  },
  mounted() {
    this.categoryData();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}

h1,h2,h3,h4,h5,h6 {
  font-family: var(--First-Font);
  font-weight: 500;
  color: var(--Second-Color);
}

.training {
  margin-top: 109px;
}
.training h1 {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: var(--Second-Color);
}
.training h5 {
  font-size: 18px;
}
.training .card-training{
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  margin-bottom: 59px;
}
.training .card-training .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.training .card-training .card-body .card-title {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--Second-Color);
  margin-bottom: 10px;
}
.training .card-training .card-body .card-text {
  font-family: var(--First-Font);
  font-family: 'FiraGO';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--Second-Color);
  margin-bottom: 18px;
}
.training .card-training .card-body .card-price {
  background: #EDEDED;
  padding: 3px 9px;
  border-radius: 10px;
  max-width: 100%;

  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--Second-Color);
}
.training .card-training .card-body .btn-motivation {
  width: 140px;
  border-radius: 10px;
}
.training .inner-description {
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--Second-Color);
}
.training .inner-description+a {
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-decoration-line: underline;

  color: #2200CC;
}


.training .signin-container {
  max-width: 472px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px 0;
  padding-top:32px;
}
.training .signin-title {
  font-family: var(--First-Font);
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 18px!important;
  line-height: 22px!important;

  text-align: center!important;
  font-feature-settings: 'case' on!important;

  color: var(--Second-Color)!important;

}
.training .signin-container label {
  font-family: var(--Third-Font)!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 16px!important;
  line-height: 19px!important;
  text-transform: lowercase!important;

  color: var(--Second-Color)!important;
  margin-bottom: 10px!important;
}
.training .auth-control {
  width: 100%!important;
  height: 56px!important;
  background: var(--input-background);
  padding: 10px 20px;
  border: 1px solid var(--input-border);
  border-radius: 10px!important;

  font-family: var(--Third-Font)!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 16px!important;
  line-height: 19px!important;

  color: rgba(66, 66, 66, 0.5);

}
.training .auth-control:hover {
  border: 1px solid var(--input-border-hover);
}
.training input::placeholder {
  color: rgba(66, 66, 66, 0.5) !important;
}
.training input:focus {
  outline: none!important;
}
.training .error-msg {
  font-family: var(--Third-Font);
}
.training .signin-container .text-center {
  margin-top:4px;
  margin-bottom: 16px;
}
.training .signin-container .text-center span {
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: var(--Second-Color);
  margin-right: 16px;
}
.training .signin-container .text-center a {
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;

  color: #31319D;
}
.training .signin-container .text-center a:hover {
  text-decoration-line: underline;
}
.training .signin-container .btn-auth {
  width: 100%;
  height: 54px;
  background: #FFDD04;
  border: 1px solid transparent;
  border-radius: 10px;

  text-align: center;
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: var(--Second-Color);
  outline: none!important;
  transition: 0.3s;
}
.training button.btn-auth:disabled {
  opacity: 0.65;
  pointer-events: none;
}
.training .signin-container .btn-auth:hover {
  background: var(--Second-Color-Background-Btn-Hover);
}
.training .row .d-1:nth-child(3) {
  display: none;
}
.training .row .d-1 span:nth-of-type(1) {
  max-width: 472px;
  width: 100%;
}
.modal-footer button {
  font-family: var(--Third-Font)!important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.modal-body {
  font-family: var(--Third-Font)!important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
textarea.auth-custom-control {
  border-radius: 10px;
  width: 100%;
  background: var(--input-background);
  border: 1px solid var(--input-border);
  padding: 10px;
  font-family: var(--Third-Font);
  color: rgba(59, 59, 59, 0.7);
  font-size: 16px;
}
textarea.auth-custom-control:hover {
  border: 1px solid var(--input-border-hover);
}
@media screen and (max-width: 1366px) {
  .training .img-fluid {
    max-width: 500px;
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .training .row .d-1:nth-child(1),
  .training .row .d-1:nth-child(2) {
    display: none!important;
  }
  .training .row .d-1:nth-child(3) {
    display: block;
  }
  .training .signin-container {
    max-width: 100%;
    padding-top: 32px;
  }
}
@media screen and (max-width: 767px) {
  .front-footer {
    margin-top: 268px;
  }
  .training {
    margin-top: 89px;
  }
  .training .card-training {
    margin-bottom: 20px;
  }
  .training .auth-control {
    height: 50px;
  }
  .training .signin-title {
    font-size: 16px!important;
    line-height: 19px!important;
  }
  .training .signin-container label {
    margin-bottom: 16px;
    font-size: 14px!important;
    line-height: 17px!important;
  }
  .training .signin-container .text-center span {
    font-size: 14px!important;
    line-height: 17px!important;
  }
  .training .signin-container .text-center a {
    font-size: 14px!important;
    line-height: 17px!important;
  }
  .training .signin-container .btn-auth {
    font-size: 14px;
    height: 50px;
  }
  .training .card-training .card-body .img-fluid {
    max-width: 150px;
    width: 100%;
  }
  .training .card-training .card-body .card-title {
    font-size: 14px;
  }
  .training .card-training .card-body .card-text {
    font-size: 14px;
  }
  .training .card-training .card-body .card-price {
    font-size: 16px;
  }
  .training .inner-description {
    font-size: 14px;
  }
  .training input::placeholder {
    font-size: 14px;
  }
  .modal-body .check_Status {
    width: 100px;
    height: 100px;
  }
}
</style>