<template>
  <loading :active="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"

           :z-index="10000"
           :color="'var(--First-Color)'"></loading>

  <div class="container-fluid">
    <div class="card align-items-center">
      <img
          class="card-img-top"
          alt="image"
          src="../../assets/image/Ellipse104.png">
      <div class="card-body d-flex flex-column  align-items-center">
        <p class="card-text">
          ჩვენ ვეხმარებით ადამიანებს დასაქმებაში და მეტი შემოსავლების მიღებაში
        </p>
        <div class="link">
          <button @click="setCoockie" type="button" class="btn">
            შემდეგი
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue3-loading-overlay';

export default {
  name: "WelcomePage",
  data() {
    return {
      isLoading: true,
    }
  },
  components: {
    Loading
  },
  methods: {
    onCancel() {

    },
    setCoockie() {
      document.cookie = "motivation=Welcome; max-age="+60*60*24*30
      if(document.cookie) {
        this.$router.push('/Home');
      } else {
        alert("Cookie can't be set!")
      }
    },
    checkCookie() {
      const value = document.cookie;
      const checkCookie = value.match("motivation=Welcome");
      if (checkCookie) {
          this.isLoading = true;
          this.$router.push('/Home');
      }
    }
  },
  mounted() {
    this.checkCookie();
    setTimeout(() => {
      this.isLoading = false
    },1000)
  }
}
</script>


<style scoped>

.card {
  margin-top: 141px;
  width: auto;
  border: none;
  padding: 0;
}

.card-img-top {
  width: 400px;
  height: 400px;
  object-fit: cover;
}


.card-body {
  padding: 0;
  margin-top: 64px;
}

.card-text {
  width: 546px;
  height: auto;
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;

  text-align: center;
  font-feature-settings: 'case' on;

  color: var(--Second-Color);
  margin: 0;
}

.btn {
  height: 60px;
  width: 442px;
  border-radius: 20px;
  background: var(--First-Color);
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 52px;
  border: none;

  color: var(--Second-Color)!important;

  transition: all 0.3s;
}


.btn:hover {
  transform: translateY(-3px);
  background: var(--Second-Color-Background-Btn-Hover) !important;
}


@media (max-width: 1600px) {
  .card {
    margin-top:70px;
  }
}
@media (max-width: 1399px) {
  .card {
    margin-top: 50px;
  }
  .card-body {
    margin-top:50px
  }
  .btn {
    width: 380px;
    height: 55px;
    margin-top:50px
  }
  .card-img-top {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 650px) {
  .card-img-top {
    width: 390px;
    height: 390px;
  }

  .card-body {
    margin-top: 68px;
    padding: 0;
    width: 500px;
  }

  .card-text {
    font-size: 22px;
    line-height: 30px;
    width: 100%;
  }

  .btn {
    margin-top: 68px;
  }

}


@media (max-width: 600px) {
  .card-img-top {
    width: 385px;
    height: 385px;
  }

  .card-body {
    width: 450px;
  }

  .btn {
    height: 60px;
    width: 400px;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;
  }

}


@media (max-width: 550px) {
  .card-img-top {
    width: 375px;
    height: 375px;
  }

  .card-body {
    width: 430px;
  }

  .card-text {
    font-size: 23px;
  }


}


@media (max-width: 500px) {
  .card-img-top {
    width: 350px;
    height: 350px;
  }

  .card-body {
    width: 390px;
  }

  .card-text {
    font-size: 19px;
    line-height: 27px;
  }

  .btn {
    height: 55px;
    width: 370px;
    font-size: 17px;
  }
}

@media (max-width: 428px) {
  .card {
    margin-top: 141px;
  }
  .card-img-top {
    width: 328px;
    height: 328px;
  }

  .card-body {
    width: 368px;
  }

  .card-text {
    font-size: 18px;
    line-height: 28px;
  }

  .btn {
    width: 368px;
    font-size: 16px;
  }

}

@media (max-width: 427px) {
  .card {
    margin-top: 60px;
  }
}
@media (max-width: 400px) {
  .card-img-top {
    width: 318px;
    height: 318px;
  }

  .card-body {
    width: 348px;
  }


  .btn {
    width: 348px;
    font-size: 15px;
  }

}

@media (max-width: 380px) {
  .card-img-top {
    width: 300px;
    height: 300px;
  }

  .card-body {
    width: 328px;
  }

  .btn {
    height: 50px;
    width: 318px;
  }

}

@media (max-width: 350px) {
  .card-img-top {
    width: 280px;
    height: 280px;
  }

  .card-body {
    width: 318px;
  }

  .card-text {
    font-size: 17px;
  }

  .btn {
    width: 280px;
  }

}
@media (max-width: 310px) {
  .card-img-top {
    width: 260px;
    height: 260px;
  }

  .card-body {
    width: 288px;
  }

  .card-text {
    font-size: 16px;
  }

  .btn {
    width: 260px;
  }

}

</style>