<template>
  <loading :active="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"

           :z-index="10000"
           :color="'var(--First-Color)'"></loading>
  <div>
    <Header/>
    <section class="faq">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-4">
            <h1 class="font-weight-bold">ვაკანსიის განთავსება</h1>
          </div>
          <div class="col-12">

            <div class="accordion" id="accordionFlushExample">
              <div class="accordion-item" v-for="(item, index) in faqResponseData" :key="index">
                <h2 class="accordion-header" :id="`flush-headingOne`+item.id">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#flush-collapseOne`+item.id" aria-expanded="false" :aria-controls="`flush-collapseOne`+item.id">
                    {{ item.name }}
                  </button>
                </h2>
                <div :id="`flush-collapseOne`+item.id" class="accordion-collapse collapse" :aria-labelledby="`flush-headingOne`+item.id" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body" v-html="item.desc"></div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-12 mt-4">
            <a href="https://app.motivation.ge/Signup" class="float-start">
              <button class="btn-motivation border-0">რეგისტრაცია</button>
            </a>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Loading from 'vue3-loading-overlay';
// import axios from 'axios'

export default {
  name: "FAQ",
  data() {
    return {
      isLoading: true,
      faqResponseData: {},
      error: ''
    }
  },
  components: {
    Header,
    Footer,
    Loading
  },
  methods: {
    onCancel() {

    },
    FAQ() {
      this.axios.get(
          process.env.VUE_APP_BACKEND_URL+'/auth/RegGroup/loadData'
      ).then(response => {
        window.scroll(0, 0);
        this.faqResponseData = response.data.data;
        this.isLoading = false;
      }).catch(error => {
        this.error = error.response.data.error;
        console.log(error);
      })

    },
  },
  mounted() {
    this.FAQ();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}

.faq h1 {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: var(--Second-Color);
}
.faq .btn-motivation {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 11px 14px;
  margin-left: 3px;
  font-family: var(--First-Font);
  color: var(--Second-Color);
}
@media screen and (max-width: 767px) {
  .faq {
    margin-top: 89px;
  }
}
button:focus {
  box-shadow: none;
}
</style>