<template>
  <div>
    <Header/>
    <div class="training">
      <!-- Container -->
      <div class="container">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col-12 mb-4">
            <h1>სასწავლო პროგრამები</h1>
          </div>
          <!-- Col -->
          <div class="col-12">
            <!-- Card -->
            <div class="card card-training">
              <div class="card-body">
                <div class="d-flex align-items-start align-items-md-center justify-content-between justify-content-md-center">
                  <img v-if="ResponseData.file != ''" class="img-fluid" alt="training image" :src="ResponseData.file">
                  <img v-else class="img-fluid w-50" alt="training image" src="../../assets/image/noavatar.jpeg">
                  <div class="d-flex flex-column">
                    <h5 class="card-title">{{ResponseData.name}}</h5>
                    <p v-if="ResponseData.mentors" class="card-text">ლექტორი: <br> {{ResponseData.mentors }}</p>
                    <p v-if="ResponseData.price " class="card-price align-self-start">{{ ResponseData.price }} ₾</p>

                  </div>
                </div>
                <router-link :to="'/Registration/'+ResponseData.id" class="btn-motivation">რეგისტრაცია</router-link>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- Col -->
          <div class="col-12">
            <p class="inner-description" v-html="ResponseData.desc"></p>
            <a href="#">სილაბუსი</a>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
      <!-- End Container -->
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "Training",
  data() {
    return {
      ResponseData: [],
      isLoading: true
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    categoryData() {
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/traning/get_by_id/'+this.$route.params.id)
          .then(response => {
            window.scroll(0,0);
            this.ResponseData = response.data;
            this.isLoading = false;
          });
    }
  },
  mounted() {
    this.categoryData();
  }
}
</script>

<style scoped>
.footer {
  margin-top: 106px;
}

h1,h2,h3,h4,h5,h6 {
  font-family: var(--First-Font);
  font-weight: 500;
  color: var(--Second-Color);
}

.training {
  margin-top: 109px;
}
.training h1 {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: var(--Second-Color);
}
.training h5 {
  font-size: 18px;
}
.training .card-training{
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  margin-bottom: 59px;
}
.training .card-training .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.training .card-training .card-body .card-title {
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--Second-Color);
  margin-bottom: 10px;
}
.training .card-training .card-body .card-text {
  font-family: var(--First-Font);
  font-family: 'FiraGO';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--Second-Color);
  margin-bottom: 18px;
}
.training .card-training .card-body .card-price {
  background: #EDEDED;
  padding: 3px 9px;
  border-radius: 10px;
  max-width: 100%;

  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--Second-Color);
}
.training .card-training .card-body .btn-motivation {
  width: 140px;
  border-radius: 10px;
}
.training .inner-description {
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--Second-Color);
}
.training .inner-description+a {
  font-family: var(--Third-Font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-decoration-line: underline;

  color: #2200CC;
}
.training .card-training .card-body .img-fluid {
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .training {
    margin-top: 89px;
  }
  .training .card-training {
    margin-bottom: 20px;
  }
  .training .card-training .card-body {
    display: block;
  }
  .training .card-training .card-body .img-fluid {
    max-width: 150px;
    width: 100%;
  }
  .training .card-training .card-body .card-title {
    font-size: 14px;
  }
  .training .card-training .card-body .card-text {
    font-size: 14px;
  }
  .training .card-training .card-body .card-price {
    font-size: 16px;
  }
  .training .card-training .card-body .btn-motivation {
    width: 100%;
    height: 50px;
    font-size: 14px;
    margin-top: 10px;
  }
  .training .inner-description {
    font-size: 14px;
  }
  .training .inner-description+a {
    font-size: 14px;
  }
  .footer {
    margin-top: 89px;
  }
}
</style>